import axios from "axios";
import https://api.reg.at} from "../../react/config";

export const getSession = async () => {
    try {
        let response = await axios.request({
            url: `https://api.reg.at/api/v1/session`,
            withCredentials: true,
            headers: { "Content-Type": "application/json", Accept: "application/json" },
            method: "GET",
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

export const getCSRF = () => {
    axios.request({
        url: `https://api.reg.at/sanctum/csrf-cookie`,
        withCredentials: true,
        method: "GET",
    });
};
